.navbar-brand {
    color: black;
    display: flex;

    img{
      height: 10vh;
      width: 20vh;
    }
  }
  
  .navbar {
    width: 100%;
    display: flex;
    z-index: 10;
    position: fixed;
    height: 15vh;
    align-items: center;
     
    justify-content: space-between;
    align-items: center;
    background-color: rgba(33, 33, 33, 0.637);
   
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 10vh;
  }
  
  .navbar-links li {
    margin-left: 20px;
  }
  
  .navbar-links a {
    color: #e48424;

    font-weight: bolder;
    text-decoration: none;
    font-size: larger;
    transition: color 0.3s, transform 0.3s;
  }
  
  .navbar-links a:hover {
    color: rgb(255, 255, 255);
    transform: scale(1.05);
  }
  
  .navbar-toggle {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: #e48424 ;
  }
  .navbar-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: #e5e5e5;
    
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    .navbar {
      flex-direction: row;
      align-items: flex-start;
      height: 13vh;
      align-items: center;
 
     
    }

    .navbar-toggle {
      display: block;
      z-index: 99;
    }
    .navbar-brand img{
      height: 7vh;
      width: 11vh;
    }
    .navbar-links {
        flex-direction: column;
        margin-top: 10px;
        gap: 0;
        display: none;
      }
      .navbar-links li {
        margin-left: 0;
        margin-bottom: 10px;
      }
    }