/* Hero.css */
.hero1 {  
  height: 100vh;  
  background-image: url('../Assets/Images/kesricafe1.jpg');  
  background-size: cover;  
  background-position: center;  
  display: flex;  
  flex-direction: column;  
  justify-content: center;
  border: #000000 2px solid;  
  align-items: center;  
  text-align: center;  
  color: #e48424;  
  
  
  font-weight: bolder;
  background-color: #f8f0e3; /* Offwhite */  
  transition: background-color 0.3s;
  
  h1, p{
    padding: 3vh;
    border-radius: 10px;
    background-color: rgba(33, 33, 33, 0.734);
  }
}  
/* Overall hero section */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f9f9f9; /* Set your desired background color */

  .text {
    text-align: center;
   
    padding: 1rem;
    color: #333; /* Set your desired text color */
  }
  
  .text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #e48424;
  }
  
  .text p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Image */
  .image img {
    
    max-width: 98%;
    padding: 0 1vh;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }
  
}

/* Text content */

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .text h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
  .hero{
    flex-direction: column;
    min-height: 80vh;
  }
}
