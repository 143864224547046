/* FloatingContactoIcons.css */

.floating-contact-icons {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
  }
  
  .contact-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .contact-icon:hover {
    transform: scale(1.1);
  }
  
  .phone-icon {
    background-color: #0074d9;
    color: #ffffff;
  }
  
  .whatsapp-icon {
    background-color: #25d366;
    color: #ffffff;
  }
  
  .icon {
    font-size: 1.5rem;
  }
  