

.menu-container {  
  max-width: 1200px;  
  margin: auto;  
  border: #e48424 1px solid;
  background-color: #e48424;
  padding: 20px;  
  height: 70vh; /* Full height */  
  overflow-y: auto; /* Enable vertical scrolling */ 
  
  .menu-category {  
    margin-bottom: 40px;  
  }  
  
  .menu-category h2 {  
    font-size: 24px;  
    color: #333;  
    border-bottom: 2px solid #eaeaea;  
    padding-bottom: 10px;  
  }  
  
  .menu-items {  
    display: flex;  
    flex-wrap: wrap;  
    gap: 10px; /* Reduced gap for smaller boxes */  
  }  
  
  .menu-item {  
    background: white;  
    border-radius: 8px;  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
    overflow: hidden;  
    flex: 1 1 120px; /* Smaller base width */  
    height: 80px; /* Smaller fixed height */  
    transition: transform 0.2s;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    padding: 10px; /* Reduced padding */  
  }  
  
  .menu-item:hover {  
    transform: scale(1.05);  
  }  
  
  .item-details {  
    text-align: center;  
  }  
  
  .item-details h3 {  
    font-size: 14px; /* Smaller font size */  
    margin: 5px 0; /* Reduced margin */  
  }  
  
  .item-details p {  
    font-size: 12px; /* Smaller font size */  
    color: #555;  
  }  
}  



/* Responsive Styles */  
@media (max-width: 768px) {  
  .menu-item {  
    flex: 1 1 calc(50% - 10px); /* Adjust for smaller screens */  
  }  
}  

@media (max-width: 480px) {  
  .menu-item {  
    flex: 1 1 100%; /* Full width on small screens */  
  }  
}