.combined {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  background: linear-gradient(135deg, #e48424, #f7fff7);
  color: #000;

  .location {
    flex: 1;
    min-width: 300px;
    margin-right: 30px;


    .location h2 {
      font-size: 15vh;
      font-family: 'Arial', sans-serif;
      margin: 0;
      color: #222;
      /* Darker color for contrast */
    }


    .map {
      border-radius: 10px;
      overflow: hidden;
    }

    .map-iframe {
      width: 100%;
      height: 300px;
      border: 0;
    }
    
  }

  .contact {
    flex: 1;
    min-width: 300px;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;

    .contact:hover {
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
    
    .contact h2 {
      margin: 0 0 20px;
      color: #000;
    }
    
    input,
    textarea {
      width: 100%;
      padding: 12px;
      margin-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #ddd;
      box-sizing: border-box;
      transition: border-color 0.3s;
    }
    
    input:focus,
    textarea:focus {
      border-color: #000;
      outline: none;
    }
    
    button {
      width: 100%;
      padding: 12px;
      background-color: #e48424;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    button:hover {
      background-color: #d3751c;
      /* Darker shade on hover */
    }
  }


}











@media screen and (max-width: 768px) {
  .combined {
    flex-direction: column;
  }

  .location {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .location h2 {
    font-size: 10vh;
  }
}