

.bakery{

 display:flex;
 flex-direction: column;

 

.services, .products{  
    display: flex;
    padding: 3px;  
    text-align: center;  
    transition: background-color 0.3s;
   
}  
.service {  
    display: inline-block;  
    width: 70%;  
    margin: 10px;  
    background-color: #000000;  
    color: #e48424;  
    padding: 20px;  
    border-radius: 8px;  
    transition: background-color 0.3s;  
}  

.service:hover {
    background-color: #ffffff;
}
.product:hover {  
    background-color:  #000000; /* Darker Brown on hover */  
}  
.services {  
    background-color: rgba(33, 33, 33, 0.637); /* Brown */  
    color: #e48424;  
}  



.products {  
    background-color: rgba(33, 33, 33, 0.637);; /* Offwhite */  
    color: #e48424;  
   
}  



}
/* Responsive styles */  
@media screen and (max-width: 768px) {  
    


    .service {  
        display: block;
       
        width: 80%;  
        margin: 10px auto;  
    }  
    .product {  
        display: block;
       
        width: 80%;  
        margin: 10px auto;  

     
    }  
    .services, .products{
      flex-direction: column;
      align-items: center;
      
    }
    
    
}  





