.about {  
    display: flex; /* Establish a flexbox layout */  
    align-items: center; /* Center items vertically */  
    justify-content: center; /* Center items horizontally */  
    flex-direction: column; /* Arrange items vertically */  
    padding: 20px 10px; /* Padding for spacing */  
    background-color: #f9f9f9; /* Light background for contrast */  

    .about-container {  
        max-width: 300vh; /* Limit container width for better readability */  
        text-align: center; /* Center text content */  
        margin: 0 auto; /* Center the container itself */  
        display: flex;
        gap: 1vh;
        align-items: center;
    }  
    
    .abouth1 {  
         /* Responsive heading size */  
        font-weight: bolder;
        margin-bottom: 20px; /* Space below the heading */  
        color: #e48424; /* Darker text for readability */  
    }  
    
    .about-image {  
        width: 50vw; /* Full width of its container */  
        height: 50vh; /* Maintain aspect ratio */  
        border-radius: 15px; /* Round the corners of the image */  
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */  
        margin-bottom: 20px; /* Space below the image */  
    }  
    
    .about p {  
        font-size: 1.2rem; /* Font size for the paragraph */  
        color: #666; /* Lighter text for less emphasis */  
        line-height: 1.5; /* Improve readability */  
    }  
}  



/* Responsive Design */  
@media (max-width: 768px) {  
    .about h2 {  
        font-size: 2rem; /* Smaller heading size on mobile */  
    }  
   
    .about-container{
        flex-direction: column;


        .about-image {  
            width: 90vw; /* Full width of its container */  
            height: 50vh; /* Maintain aspect ratio */  
            border-radius: 15px; /* Round the corners of the image */  
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */  
            margin-bottom: 20px; /* Space below the image */  
        }  
    }

    .about p {  
        font-size: 1rem; /* Smaller paragraph text on mobile */  
    }  
}