.footer {  
    background-color: #e48424; /* Dark background */  
    color: white;           /* Text color */  
    padding: 20px;         /* Padding around the footer */  
    text-align: center; 
    align-items: center;    /* Center the text */ 
    
    .footer-content {  
      display: flex;  
      justify-content: space-between; /* Space out content */  
      align-items: center;            /* Center align items vertically */  
      flex-wrap: wrap;                /* Wrap items on smaller screens */  
      margin-bottom: 10px;           /* Space below the footer content */  

      .about-cafe {  
        flex: 1 1 120px; /* Flex grows and shrinks */  
        margin-top: 20px; /* Space above 'About Our Cafe' */  
        text-align: center;  /* Align text to left */  
      }  
    }  

    .footer-info {  
      flex: 1 1 300px; /* Flex grows and shrinks */ 
         
      .footer-contact{
        color: #ffffff;
        
        a{
          color: #ffffff;
          text-decoration: none;
        }
      }
       
    }  
  }  
  
  
  
  
  
  
  
   
  
 
  
  
  
  
  @media (max-width: 768px) {  
    .footer-content {  
      flex-direction: column;      /* Stack content vertically on small screens */  
      align-items: center;         /* Center align items */  
    }  
  
    .social-links {  
      margin-top: 10px;           /* Space above social links */  
    }  
  
    .footer-nav {  
      margin-top: 10px;           /* Space above navigation links */  
    }  
  }