.services {  
    padding: 50px 20px; /* Padding for top and bottom */  
    text-align: center; /* Center the title and cards */  
    background-color: rgba(33, 33, 33, 0.637); /* Light background color */  

    .services-container {  
      display: grid; /* Use grid for layout */  
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */  
      gap: 20px; /* Space between cards */  
      align-items: start; /* Align items at the start */  
    }  
    
    .serviceh2 {  
      font-size: 2.5rem; /* Larger font size for the heading */  
      margin-bottom: 30px; /* Space below the heading */  
      color: #e48424; /* Dark color for the text */  
      font-weight: bolder;
      font-family:monospace;
    }  
    
    
    .card {  
      background: white; /* White background for cards */  
      border-radius: 10px; /* Rounded corners */  
      padding: 20px; /* Padding inside cards */  
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */  
      transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for effects */  
    }  
    
    .card:hover {  
      transform: translateY(-5px); /* Slight lift effect on hover */  
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */  
    }  
    
    .card h3 {  
      font-size: 1.5rem; /* Font size for service titles */  
      margin-bottom: 10px; /* Space below titles */  
      color: #e48424; /* Primary color for titles */  
    }  
    
    .card p {  
      font-size: 1rem; /* Font size for descriptions */  
      color: #666; /* Gray color for the text */  
    }  
  }  
  
 
  
  @media (max-width: 768px) {  
    .services h2 {  
      font-size: 2rem; /* Smaller font size for tablet and mobile */  
    }  
  }  
  
  @media (max-width: 480px) {  
    .card {  
      padding: 15px; /* Padding adjustments for smaller screens */  
    }  
  
    .card h3 {  
      font-size: 1.25rem; /* Smaller titles on mobile */  
    }  
  
    .card p {  
      font-size: 0.9rem; /* Smaller description on mobile */  
    }  
  }