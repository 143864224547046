.gallery2{
    text-align: center;
    font-weight: bolder;
    color: #e48424;
}

.wrapper {  
    display: flex;  
    flex-wrap: wrap; /* Allows cards to wrap into multiple rows on smaller screens */  
    width: 100%; /* Makes it responsive */  
    max-width: 1200px; /* Maximum width */  
    height: auto; /* Height auto for flexibility */  
    overflow: hidden; 
    
    border-radius: 5px;  
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);  
    margin: 0 auto; /* Center the gallery */ 
    
    .card {  
        flex: 1;  
        transition: flex 0.5s ease;  
        cursor: pointer;  
        min-width: 150px; /* Minimum width for cards */  
        height: 400px; /* Fixed height for a better layout */  
    }  
    
    .card:hover {  
        flex: 3; /* Expands on hover */  
    }  
    
    .card1,  
    .card2,  
    .card3,  
    .card4 {  
        background-position: center;  
        background-size: cover;  
    }  
    
    .card1 {  
        background-image: url("../Assets/Images/cafe5.jpg");  
    }  
    
    .card2 {  
        background-image: url("../Assets/Images/cafe6.jpg");  
    }  
    
    .card3 {  
        background-image: url("../Assets/Images/cafe7.jpg");  
    }  
    
    .card4 {  
        background-image: url("../Assets/Images/cafe8.jpg");  
    }  
    
}  


/* Media Queries for responsiveness */  
@media (max-width: 600px) {  
    .card {  
        flex: 1 1 45%; /* Two items per row on small screens */  
        height: 200px; /* Fixed height */  
    }  
}  

@media (max-width: 400px) {  
    .card {  
        flex: 1 1 100%; /* One item per row on extra small screens */  
        height: 150px; /* Adjust height */  
    }  
}