#gallery-heading{
  text-align: center;
  font-weight: bolder;
  color: #e48424;
}
.carousel-gallery {
  position: relative;
  /* Allows positioning of buttons */
  max-width: 800px;
  /* Set your desired max width */
  margin: auto;
  /* Center the carousel */
  overflow: hidden;
  /* Prevent overflow of images */
  border-radius: 10px;
  /* Optional rounded corners */

  .carousel-image {
    width: 100%;
    /* Make images responsive */
    padding: 1vh;
    height: 40vh;
    /* Maintain aspect ratio */
    display: block;
    /* Ensure the image does not have a bottom margin */
  }


  .carousel-button {
    position: absolute;
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    /* Semi-transparent background */
    border: none;
    padding: 10px;
    /* Space around the buttons */
    cursor: pointer;
    /* Change pointer on hover */
    border-radius: 50%;
    /* Round buttons */
    z-index: 1;
    /* Ensure buttons are above the images */
  }

  .prev {
    left: 10px;
    /* Position left button */
  }

  .next {
    right: 10px;
    /* Position right button */
  }

  .carousel-button:hover {
    background: rgba(255, 255, 255, 1);
    /* Full opacity on hover */
  }

}








@media (max-width: 600px) {
  .carousel {
    max-width: 100%;
    
    /* Full width on smaller screens */
  }
  .project-box{
    display: flex;
    
    justify-content: center;

  }

  .carousel-button {
    padding: 5px;
    /* Smaller padding on mobile */
  }
}